<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <v-btn :to="`/${organizationUrl}`" color="primary" plain>
          <v-icon left>mdi-arrow-left</v-icon>Revenir au planning
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="6">
        <div v-if="token">
          <v-card class="elevation-12">
            <v-toolbar color="deep-orange lighten-3" dark flat>
              <v-toolbar-title>{{
                $helpers.string.capitalizeI18N("reset-password")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert v-if="message" :type="successful ? 'success' : 'error'">{{ message }}</v-alert>
              <v-form ref="form" @submit.prevent="handleResetPasswordFromToken">
                <v-text-field :type="showPassword ? 'text' : 'password'" :label="$helpers.string.capitalizeI18N('new-password', {
                  required: true,
                })
                  " v-model="password" name="password" prepend-icon="lock" autofocus required
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
                  :rules="$helpers.rule.notEmpty()" />
                <v-btn color="deep-orange lighten-3" dark @click="handleResetPasswordFromToken" :disabled="loading" block>
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>{{ $t("continue") }}</span>
                </v-btn>
                <div class="mt-4 text-caption">
                  * {{ $t("required-fields") }}
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <v-card class="elevation-12">
            <v-toolbar color="deep-orange lighten-3" dark flat>
              <v-toolbar-title>{{
                $helpers.string.capitalizeI18N("reset-password")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert v-if="message" :type="successful ? 'success' : 'error'">{{ message }}</v-alert>
              <v-form ref="form" @submit.prevent="handleResetPassword">
                <v-text-field :label="'e-mail ou code client *'" v-model="login" prepend-icon="mdi-account"
                  :rules="$helpers.rule.notEmpty()" />
                <v-btn color="deep-orange lighten-3" dark @click="handleResetPassword" :disabled="loading" block>
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>{{ $t("recover-my-password") }}</span>
                </v-btn>
                <div class="mt-4 text-caption">
                  * {{ $t("required-fields") }}
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col>
                    <div>
                      <v-btn class="font-weight-medium" text :to="$helpers.router.getOrganizationLink('login')"
                        color="deep-orange lighten-3" dark>Vous avez déjà un compte ?</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>

              <!-- <v-btn
                :to="$helpers.router.getOrganizationLink('register')"
                outlined
                dark
                color="deep-orange lighten-3"
              >
                <v-icon>mdi-account-plus</v-icon
                ><span class="ml-2 d-none d-md-inline">{{
                  $t("no-account-register")
                }}</span>
              </v-btn>
              <v-btn
                :to="$helpers.router.getOrganizationLink('login')"
                outlined
                dark
                color="deep-orange lighten-3"
              >
                <v-icon>mdi-account-reactivate</v-icon
                ><span class="ml-2 d-none d-md-inline">{{ $t("login") }}</span>
              </v-btn>

              <v-spacer />
              <v-btn
                dark
                color="deep-orange lighten-3"
                @click="handleResetPassword"
                :disabled="loading"
              >
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span class="mr-2 d-none d-md-inline">{{
                  $t("continue")
                }}</span>
                <v-icon small>mdi-arrow-right</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      login: null,
      loading: false,
      message: "",
      successful: false,
      showPassword: false,
      password: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.publicorganizationauth.client;
    },
    token() {
      return this.$route.query.token;
    },
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$helpers.router.push("/" + this.organizationUrl);
    }
  },
  methods: {
    handleResetPassword() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.$store
        .dispatch("publicorganizationauth/resetPassword", this.login)
        .then(
          () => {
            this.loading = false;
            this.successful = true;
            this.message = this.$t("reset-password-confirmation-email");
          },
          (error) => {
            this.loading = false;

            this.$helpers.snackbar.handleError(error);
          }
        );
    },
    handleResetPasswordFromToken() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      this.$store
        .dispatch("publicorganizationauth/resetPasswordFromToken", {
          token: this.token,
          password: this.password,
        })
        .then(
          () => {
            this.loading = false;

            this.$router.push({
              path: this.$helpers.router.getOrganizationLink("login"),
              query: { resetpassword: 1 },
            });
          },
          (error) => {
            this.loading = false;

            this.$helpers.snackbar.handleError(error);
          }
        );
    },
  },
};
</script>